<template>
  <v-card class="elevation-0">
    <v-toolbar
      dense
      card
    >
      <v-toolbar-title><small>Mobile numbers</small></v-toolbar-title>
      <v-spacer />
      <v-speed-dial
        v-model="fab"
        top
        fixed
        open-on-hover
        right
      >
        <v-btn
          slot="activator"
          :disabled="disabled"
          :color="spClass"
          fab
          dark
          small
          @click.native.stop="showAddSp"
        >
          <v-icon>{{ spIcon }}</v-icon>
          <span>SP</span>
        </v-btn>
        <!-- <v-tooltip bottom>
          <v-btn
            slot="activator"
            :disabled="disabled"
            :color="spClass"
            fab
            dark
            small
            @click.native.stop="showAddSp"
          >
            <v-icon>{{ spIcon }}</v-icon>
          </v-btn>
          <span>SP</span>
        </v-tooltip> -->
        <!--v-tooltip bottom>
          <v-btn
            fab
            dark
            :disabled="disabled"
            slot="activator"
            small
            @click.native.stop="showAddSp"
            :color="searchClass"
          >
            <v-icon>{{ searchIcon }}</v-icon>
          </v-btn>
          <span>Special request</span>
        </v-tooltip-->
      </v-speed-dial>
    </v-toolbar>
    <v-list
      dense
      two-line
      subheader
    >
      <v-subheader inset>
        SP list
      </v-subheader>
      <v-list-tile
        v-for="(thisItem, i) in items"
        :key="thisItem.msisdn"
        avatar
        @click="showEditRegistrationItem(i)"
      >
        <v-list-tile-avatar>
          <v-icon :color="item.iconClass">
            {{ thisItem.icon }}
          </v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title>{{ thisItem.detail }}</v-list-tile-title>
          <v-list-tile-sub-title>{{ thisItem.iccid }}</v-list-tile-sub-title>
        </v-list-tile-content>
        <v-btn
          :ripple="false"
          icon
          @click.stop="deleteMsisdn(i)"
        >
          <v-icon color="grey">
            mdi-delete-circle
          </v-icon>
        </v-btn>
      </v-list-tile>
      <!--v-alert
        :color="listAlert"
        :icon="listAlert"
        :value="!items.length"
      >
        <p>Please add at least one number to register.</p>
        <p><strong>TIPS -&nbsp;</strong>You can register a combination of SP, New and MNP numbers under one registration profile all at once.</p>
      </v-alert-->
      <v-dialog
        v-model="spDialog"
        :max-width="maxWidth"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        persistent
      >
        <sp-dialog-prepaid
          v-model="items"
          :dialog.sync="spDialog"
        />
      </v-dialog>
      <!-- <v-dialog
        v-model="newDialog"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        persistent
        max-width="550px"
      >
        <new-msisdn-dialog
          v-model="items"
          :dialog.sync="newDialog"
          :selections="selections"
        />
      </v-dialog>
      <v-dialog
        v-model="updateDialog"
        :max-width="maxWidth"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        persistent
      >
        <edit-msisdn-dialog
          v-model="registrationItem"
          :dialog.sync="updateDialog"
        />
      </v-dialog> -->
    </v-list>
  </v-card>
</template>

<script>
import formField from '@/mixins/form-field'
import { maxRegistrations } from '@/config'
import {
  searchClass, searchIcon,
  spClass, spIcon,
} from '@/utils'

export default {
  components: {
    SpDialogPrepaid: () => import(/* webpackChunkName: "spDialogPrepaid" */ '@/components/SpDialogPrepaid'),
  },
  mixins: [formField],
  data () {
    return {
      editIndex: -1,
      fab: false,
      initialDonor: null,
      item: {
        iccid: null,
        icon: null,
        iconClass: null,
        msisdn: null,
        // price: null,
        // sellingPrice: null,
        value: false,
      },
      listAlert: 'info',
      maxWidth: '390px',
      selections: [],
      searchClass: searchClass,
      searchIcon: searchIcon,
      spClass: spClass,
      spDialog: false,
      spIcon: spIcon,
    }
  },
  computed: {
    disabled: function () {
      let count = 0
      this.items.forEach(item => {
        if (item.icon === this.spIcon) count++
      })
      return count >= maxRegistrations
    },
    items: function () {
      return this.field.props.items
    },
    registrationItem: function () {
      return this.editIndex > -1 ? this.items[this.editIndex] : this.item
    },
  },
  mounted: function () {
    this.$set(this.field, 'validate', val => {
      if (this.items.length) return true
      else {
        this.listAlert = 'error'
        return false
      }
    })
  },
  methods: {
    deleteMsisdn: function (index) {
      this.items.splice(index, 1)
      this.editIndex = -1
    },
    showAddSp: function () {
      this.spDialog = true
    },
  },
}
</script>

<!--style lang="stylus" scoped>
.speed-dial--top.speed-dial--absolute {
    top: 75%;
    transform: translateY(-50%);
}
</style-->
